import React, { useEffect, useState } from 'react';

function SelectErrorMessage({
  errors,
  fieldId,
  useWatch,
  control,
  isSubmitted,
  message = 'A selection is required',
}) {
  const [hasInteracted, setHasInteracted] = useState(false);
  const fieldValue = useWatch({
    control,
    name: fieldId,
  });
  useEffect(() => {
    if (fieldValue !== '') {
      setHasInteracted(true);
    }
  }, [fieldValue]);
  return (
    (hasInteracted || isSubmitted) &&
    errors[fieldId] && (
      <div className="text-xs font-bold text-platform-primary-red-800 ">
        {message}
      </div>
    )
  );
}

export default SelectErrorMessage;
