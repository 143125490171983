import React from 'react';
import ProjectsTable from './ProjectsTable';
import { useAPI } from '../../app/apiContext';
import FormLayout from '../../components/dynamic-form/bsol_form_layout.json';

export default function Projects() {
  const { projects } = useAPI();

  const formattedProjects = projects.map((p) =>
    p.duplicate_of !== null ? { ...p, status: 'Duplicate' } : p,
  );

  return (
    <div className="flex flex-col h-screen">
      <ProjectsTable
        projects={[
          ...formattedProjects.filter((p) => p.status === 'Delivered'),
          ...formattedProjects.filter((p) => p.status === 'Potential'),
          ...formattedProjects.filter((p) => p.status === 'Blocked'),
          ...formattedProjects.filter((p) => p.status === 'Planned'),
          ...formattedProjects.filter((p) => p.status === 'Duplicate'),
          ...formattedProjects.filter((p) => p.status === 'Deleted'),
        ]}
        formLayout={FormLayout}
      />
    </div>
  );
}
