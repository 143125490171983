import React from 'react';
import ReactPlayer from 'react-player';

function HomeVideoGrid() {
  const isLoading = false;
  const videoWidth = 400;
  const videoHeight = 250;

  return (
    <div className="bg-platform-section-grey-400">
      {isLoading ? (
        <div className="grid place-content-center justify-center z-50 pr-4">
          <div className="flex justify-center mt-2">
            <div className="bg-platform-primary-red-800 p-2 mx-1 rounded-full animate-loader" />
            <div className="bg-platform-primary-orange-800 p-2 mx-1 rounded-full animate-loader animation-delay-100" />
            <div className="bg-platform-secondary-orange-800 p-2 mx-1 rounded-full animate-loader animation-delay-200" />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center border border-platform-primary-grey-200 rounded-lg shadow p-1">
          <ReactPlayer
            light="https://advinc-html-image-store.s3.eu-west-1.amazonaws.com/WorkplanVideos/workplan_v2_home_page_training_thumbnail.png"
            config={{
              file: { attributes: { controlsList: 'nodownload' } },
            }}
            onContextMenu={(e) => e.preventDefault()}
            url="https://advinc-html-image-store.s3.eu-west-1.amazonaws.com/WorkplanVideos/workplan_v2_home_page_training.mp4"
            playing
            controls
            width={videoWidth}
            height={videoHeight}
          />
        </div>
      )}
    </div>
  );
}

export default HomeVideoGrid;
