import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { CSVLink } from 'react-csv';
import { HiDocumentDownload } from 'react-icons/hi';
import dateFormat from 'dateformat';
import { useAPI } from '../../app/apiContext';

export default function CsvExportButton({ projects, formLayout, allProjects }) {
  const [csvData, setCsvData] = useState([]);
  const { trusts, divisions, cipCategories, leads } = useAPI();

  function findDuplicate(project, projectsToParse) {
    const duplicate = projectsToParse.find(
      (proj) => proj?.id === project?.duplicate_of,
    );

    if (duplicate?.status === 'Duplicate') {
      return findDuplicate(duplicate, projectsToParse);
    }

    return duplicate;
  }

  const csvOptions = {
    trusts,
    leads,
    divisions,
    cipCategories,
  };
  const csvHeaders = [
    { label: 'Project Name', key: 'title' },
    { label: 'Status', key: 'status' },
    { label: 'Trust', key: 'trust' },
    { label: 'Project Lead', key: 'project_lead' },
    { label: 'Division', key: 'division' },
    { label: 'Department', key: 'dept' },
    { label: 'Project Ref', key: 'project_ref' },
    { label: 'Anticipated Delivery', key: 'anticipated_delivery' },
    { label: 'Actual Delivery', key: 'actual_delivery' },
    { label: 'Financial Year', key: 'financial_year' },
    { label: 'Total Contract Value (£)', key: 'contract_value' },
    { label: 'CIP Category', key: 'cip_category' },
    { label: 'Savings (£)', key: 'cip_target' },
    { label: 'In-Year Saving (£)', key: 'current_year_saving' },
    { label: 'Carry Over (£)', key: 'next_year_saving' },
    { label: '12-Month Saving (£)', key: 'twelve_monthly_saving' },
    { label: 'CIP Tracking Ref', key: 'cip_ref' },
    { label: 'Recurring?', key: 'is_recurrent' },
    { label: 'CIP Approved By', key: 'approved_by' },
    { label: 'AdviseInc Service Used?', key: 'used_pd' },
    { label: 'Project ID', key: 'id' },
    { label: 'Creation Date', key: 'date_created' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'Notes', key: 'notes' },
    { label: 'Duplicate Of', key: 'duplicate_of' },
    { label: 'CM Classification', key: 'cm_classification' },
    { label: 'Main Cpv Category', key: 'main_cpv_category' },
    { label: 'Main Alternative Category', key: 'main_alternative_category' },
    { label: 'Contract Duration', key: 'contract_duration' },
    { label: 'Estimated Extension Length', key: 'estimated_extension_length' },
    { label: 'Estimated Extension Value', key: 'estimated_extension_value' },
    { label: 'Savings Type', key: 'savings_type' },
    { label: 'Savings Category', key: 'savings_category' },
    {
      label: 'Public Procurement Regulations',
      key: 'public_procurement_regulations',
    },
    { label: 'Procurement Route', key: 'procurement_route' },
    { label: 'Contract Type', key: 'contract_type' },
    {
      label: 'Financial Year Savings 2022/23',
      key: 'financial_year_savings_2022',
    },
    {
      label: 'Financial Year Savings 2023/24',
      key: 'financial_year_savings_2023',
    },
    {
      label: 'Financial Year Savings 2024/25',
      key: 'financial_year_savings_2024',
    },
    {
      label: 'Financial Year Savings 2025/26',
      key: 'financial_year_savings_2025',
    },
    {
      label: 'Financial Year Savings 2026/27',
      key: 'financial_year_savings_2026',
    },
    {
      label: 'Financial Year Savings 2027/28',
      key: 'financial_year_savings_2027',
    },
    {
      label: 'Financial Year Savings 2028/29',
      key: 'financial_year_savings_2028',
    },
    {
      label: 'Financial Year Savings 2029/30',
      key: 'financial_year_savings_2029',
    },
    {
      label: 'Financial Year Savings 2030/31',
      key: 'financial_year_savings_2030',
    },
    {
      label: 'Financial Year Savings 2031/32',
      key: 'financial_year_savings_2031',
    },
    {
      label: 'Financial Year Savings 2032/33',
      key: 'financial_year_savings_2032',
    },
  ];

  const getFinancialYearSavings = (project) => {
    const fiscalYears = [
      {
        label: 'Financial Year Savings 2022',
        key: 'financial_year_savings_2022',
        start: '2022-04-01',
        end: '2023-03-31',
      },
      {
        label: 'Financial Year Savings 2023',
        key: 'financial_year_savings_2023',
        start: '2023-04-01',
        end: '2024-03-31',
      },
      {
        label: 'Financial Year Savings 2024',
        key: 'financial_year_savings_2024',
        start: '2024-04-01',
        end: '2025-03-31',
      },
      {
        label: 'Financial Year Savings 2025',
        key: 'financial_year_savings_2025',
        start: '2025-04-01',
        end: '2026-03-31',
      },
      {
        label: 'Financial Year Savings 2026',
        key: 'financial_year_savings_2026',
        start: '2026-04-01',
        end: '2027-03-31',
      },
      {
        label: 'Financial Year Savings 2027',
        key: 'financial_year_savings_2027',
        start: '2027-04-01',
        end: '2028-03-31',
      },
      {
        label: 'Financial Year Savings 2028',
        key: 'financial_year_savings_2028',
        start: '2028-04-01',
        end: '2029-03-31',
      },
      {
        label: 'Financial Year Savings 2029',
        key: 'financial_year_savings_2029',
        start: '2029-04-01',
        end: '2030-03-31',
      },
      {
        label: 'Financial Year Savings 2030',
        key: 'financial_year_savings_2030',
        start: '2030-04-01',
        end: '2031-03-31',
      },
      {
        label: 'Financial Year Savings 2031',
        key: 'financial_year_savings_2031',
        start: '2031-04-01',
        end: '2032-03-31',
      },
      {
        label: 'Financial Year Savings 2032',
        key: 'financial_year_savings_2032',
        start: '2032-04-01',
        end: '2033-03-31',
      },
    ];
    fiscalYears.forEach((fy) => {
      project[fy.key] = 0;
    });
    let savingsBreakdown;
    try {
      savingsBreakdown = JSON.parse(project.savings_breakdown);
      if (!Array.isArray(savingsBreakdown)) {
        throw new Error('Invalid savings_breakdown format');
      }
    } catch (e) {
      return;
    }

    savingsBreakdown.forEach((entry) => {
      const entryDate = new Date(`${entry.month}-02`);
      fiscalYears.forEach((fy) => {
        const startDate = new Date(fy.start);
        const endDate = new Date(fy.end);
        if (entryDate >= startDate && entryDate <= endDate) {
          project[fy.key] += parseFloat(entry.amount);
        }
      });
    });
    fiscalYears.forEach((fy) => {
      project[fy.key] = (project[fy.key] / 100).toFixed(2);
    });
  };

  const handleCsvClick = () => {
    const csvProjects = [
      ...projects.filter((p) => p.status === 'Delivered'),
      ...projects.filter((p) => p.status === 'Potential'),
      ...projects.filter((p) => p.status === 'Blocked'),
      ...projects.filter((p) => p.status === 'Planned'),
      ...projects.filter((p) => p.status === 'Deleted'),
      ...projects.filter((p) => p.status === 'Duplicate'),
    ].map((project) => {
      if (project.savings_breakdown) {
        try {
          const parsed = JSON.parse(project.savings_breakdown);
          if (Array.isArray(parsed)) {
            getFinancialYearSavings(project);
          }
        } catch (e) {
          // pass
        }
      }

      return formLayout
        .map((page) =>
          page.rows.map((row) =>
            row.fields.map((field) => ({
              id: field.uid,
              component: field.component,
              options: 'options' in field ? field.options : null,
            })),
          ),
        )
        .flat(2)
        .reduce(
          (obj, item) => ({
            ...obj,
            [item.id]:
              item.component === 'currency'
                ? (project[item.id] / 100).toFixed(2).toLocaleString()
                : item.component === 'date'
                ? dateFormat(project[item.id], 'dd/mm/yy')
                : item.component === 'select' && csvOptions[item.options]
                ? csvOptions[item.options]
                    .filter((o) => o.id === project[item.id])
                    .map((o) => o.name)
                    .toString()
                : project[item.id],
            twelve_monthly_saving: (
              (project.current_year_saving + project.next_year_saving) /
              100
            )
              .toFixed(2)
              .toLocaleString(),
            date_created: dateFormat(project.date_created, 'dd/mm/yy'),
            financial_year: project.financial_year,
            id: project.id,
            current_year_saving: (project.current_year_saving / 100)
              .toFixed(2)
              .toLocaleString(),
            next_year_saving: (project.next_year_saving / 100)
              .toFixed(2)
              .toLocaleString(),
            anticipated_delivery:
              project.anticipated_delivery === null
                ? ''
                : project.anticipated_delivery,
            status:
              project?.status === 'Duplicate'
                ? findDuplicate(project, allProjects)?.status
                : project?.status,
            actual_delivery:
              project.actual_delivery === null ? '' : project.actual_delivery,
            duplicate_of: project?.duplicate_of,
            route_to_market: project?.route_to_market,
            cm_classification: project?.cm_classification,
            main_cpv_category: project?.main_cpv_category,
            main_alternative_category: project?.main_alternative_category,
            contract_duration: project?.contract_duration,
            estimated_extension_length: project?.estimated_extension_length,
            estimated_extension_value: project?.estimated_extension_value,
            savings_type: project?.savings_type,
            savings_category: project?.savings_category,
            procurement_route: project?.procurement_route,
            contract_type: project?.contract_type,
            public_procurement_regulations:
              project?.public_procurement_regulations,
            financial_year_savings_2022: project.financial_year_savings_2022,
            financial_year_savings_2023: project.financial_year_savings_2023,
            financial_year_savings_2024: project.financial_year_savings_2024,
            financial_year_savings_2025: project.financial_year_savings_2025,
            financial_year_savings_2026: project.financial_year_savings_2026,
            financial_year_savings_2027: project.financial_year_savings_2027,
            financial_year_savings_2028: project.financial_year_savings_2028,
            financial_year_savings_2029: project.financial_year_savings_2029,
            financial_year_savings_2030: project.financial_year_savings_2030,
            financial_year_savings_2031: project.financial_year_savings_2031,
            financial_year_savings_2032: project.financial_year_savings_2032,
          }),
          {},
        );
    });

    setCsvData(csvProjects);
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div>
            <CSVLink
              data={csvData}
              asyncOnClick={false}
              onClick={() => {
                handleCsvClick();
              }}
              headers={csvHeaders}
              filename={`workplan-projects-${new Date()
                .toISOString()
                .split('T')[0]
                .replace(/-/g, '')}.csv`}>
              <div className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiDocumentDownload className="text-xl" />
              </div>
            </CSVLink>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
            Export Projects
            <Tooltip.Arrow className="fill-platform-primary-grey-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
