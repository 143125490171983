import React, { useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import { HiChevronLeft, HiDocumentAdd, HiHome } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from '../add-project/CreateProjectModal';
import AtamisExportButton from './AtamisExportButton';
import CsvExportButton from './CsvExportButton';
import ClearFiltersButton from './ClearFiltersButton';

export default function ButtonBar({
  formLayout,
  projectsToExport,
  clearFilters,
  allProjects,
}) {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const navigate = useNavigate();

  const ref = useRef();

  return (
    <div className="flex w-maxFull px-2 py-1 items-center justify-between bg-platform-ainc-grey-400 border border-platform-secondary-grey-200">
      <button
        type="button"
        onClick={() => navigate('/home')}
        className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
        <div className="flex items-center justify-between">
          <HiChevronLeft className="text-2xl" /> Home{' '}
          <HiHome className="text-xl ml-1" />
        </div>
      </button>
      <div className="flex">
        <div>
          <ClearFiltersButton clearFilters={clearFilters} />
        </div>
        <div>
          <Dialog.Root
            open={showCreateProjectModal}
            onOpenChange={setShowCreateProjectModal}>
            <Dialog.Trigger>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <div className="mx-1 my-0.5 p-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                      <span>
                        <HiDocumentAdd className="text-xl" />
                      </span>
                    </div>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      side="bottom"
                      className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                      Quick Create
                      <Tooltip.Arrow className="fill-platform-primary-grey-800" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
              <CreateProjectModal
                setShowModal={setShowCreateProjectModal}
                ref={ref}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <AtamisExportButton
            formLayout={formLayout}
            projects={projectsToExport}
          />
        </div>
        <div>
          <CsvExportButton
            projects={projectsToExport}
            formLayout={formLayout}
            allProjects={allProjects}
          />
        </div>
      </div>
    </div>
  );
}
