import React from 'react';
import { workplanVersion } from '../version-config';

function Footer() {
  return (
    <footer className="w-maxFull bottom-0 h-[30px]">
      <div className="text-left px-2">
        <span className="text-platform-primary-grey-800">
          <small>Copyright {new Date().getFullYear()} AdviseInc Ltd. </small>
        </span>
        <span className="text-platform-primary-grey-800">
          <small>Version {workplanVersion.version}</small>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
