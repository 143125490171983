import React from 'react';
import './loader_dots.css';

export function LoaderDots() {
  return (
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
}
