import React from 'react';
import { Outlet } from 'react-router-dom';
import { APIContextProvider } from '../app/apiContext';

export default function ContextLayout() {
  return (
    <APIContextProvider>
      <Outlet />
    </APIContextProvider>
  );
}
