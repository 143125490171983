import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as XLSX from 'xlsx';
import dateFormat from 'dateformat';
import AtamisLogo from '../../assets/atamis.png';
import { atamisExportFields } from '../../app/lookups';
import { useAPI } from '../../app/apiContext';

export default function AtamisExportButton({ formLayout, projects }) {
  const fileName =
    'Atamis Export' +
    `-${new Date().toISOString().split('T')[0].replace(/-/g, '')}`;
  const fileExtension = '.xlsx';

  const { trusts, divisions, cipCategories, leads } = useAPI();

  const options = {
    trusts,
    leads,
    divisions,
    cipCategories,
  };

  const handleExcelExport = async () => {
    const formattedProjects = projects
      .filter((p) => p.status === 'Potential')
      .map((project) =>
        formLayout
          .map((page) =>
            page.rows.map((row) =>
              row.fields.map((field) => ({
                id: field.uid,
                component: field.component,
                options: 'options' in field ? field.options : null,
              })),
            ),
          )
          .flat(2)
          .reduce(
            (obj, item) => ({
              ...obj,
              [item.id]:
                item.component === 'currency'
                  ? (project[item.id] / 100).toFixed(2).toLocaleString()
                  : item.component === 'date'
                  ? dateFormat(project[item.id], 'dd/mm/yy')
                  : item.component === 'select' && options[item.options]
                  ? options[item.options]
                      .filter((o) => o.id === project[item.id])
                      .map((o) => o.name)
                      .toString()
                  : project[item.id],
              twelve_monthly_saving: (
                (project.current_year_saving + project.next_year_saving) /
                100
              )
                .toFixed(2)
                .toLocaleString(),
              date_created: dateFormat(project.date_created, 'dd/mm/yy'),
              financial_year: project.financial_year,
              project_id: project.id,
            }),
            {},
          ),
      );
    const exportData = formattedProjects.map((project) =>
      atamisExportFields.map((field) =>
        field.id === '' ? '' : project[field.id],
      ),
    );
    const headers = atamisExportFields.map((field) => field.label);
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Atamis Fields');
    XLSX.utils.sheet_add_aoa(worksheet, [headers], {
      origin: 'A1',
    });
    XLSX.writeFile(workbook, fileName + fileExtension, { compression: true });
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            aria-label="handle-export-button"
            className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400"
            type="button"
            onClick={() => handleExcelExport()}>
            <img src={AtamisLogo} alt="" width="20px" />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
            Export For Atamis
            <Tooltip.Arrow className="fill-platform-primary-grey-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
