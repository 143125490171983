import React, { useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAPI } from '../../app/apiContext';
import {
  buildBoolean,
  buildCurrency,
  buildDate,
  buildFieldFromId,
  buildStatus,
  buildTitle,
} from '../../app/tableHelper';
import CsvExportHistoryButton from './CsvExportHistoryButton';

export default function ProjectHistoryTable({ projectHistory, formLayout }) {
  const { cipCategories } = useAPI();
  const [hideNotes, setHideNotes] = useState(true);

  const getHeader = (columnId) =>
    formLayout
      .map((page) =>
        page.rows.map((row) =>
          row.fields.map((field) => ({ id: field.uid, label: field.label })),
        ),
      )
      .flat(2)
      .filter((h) => h.id === columnId)
      .map((h) => h.label)
      .toString();

  const buildHistoryNotes = (info) => {
    const { notes } = info.row.original;
    return (
      <div className="flex">
        <div className={`justify-start w-500 ${hideNotes && 'truncate'}`}>
          {notes}
        </div>
        <button
          type="button"
          onClick={() => setHideNotes(!hideNotes)}
          className="mx-0.5 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          <div className="mx-2 my-1"> {hideNotes ? '+' : '-'}</div>
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.action, {
      id: 'action',
      header: 'Action',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.date_updated, {
      id: 'date_updated',
      header: 'Date Last Updated',
      cell: (info) => buildDate(info.getValue()),
    }),
    columnHelper.accessor((row) => row.title, {
      id: 'title',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildTitle(info),
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildStatus(info),
    }),
    columnHelper.accessor((row) => row.trust, {
      id: 'trust',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.project_lead, {
      id: 'project_lead',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.division, {
      id: 'division',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.dept, {
      id: 'dept',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.project_ref, {
      id: 'project_ref',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.anticipated_delivery, {
      id: 'anticipated_delivery',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildDate(info.getValue()),
    }),
    columnHelper.accessor((row) => row.actual_delivery, {
      id: 'actual_delivery',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildDate(info.getValue()),
    }),
    columnHelper.accessor((row) => row.contract_value, {
      id: 'contract_value',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildCurrency(info.getValue()),
    }),
    columnHelper.accessor((row) => row.cip_category, {
      id: 'cip_category',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildFieldFromId(info.getValue(), cipCategories),
    }),
    columnHelper.accessor((row) => row.cip_target, {
      id: 'cip_target',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildCurrency(info.getValue()),
    }),
    columnHelper.accessor((row) => row.current_year_saving, {
      id: 'current_year_saving',
      header: 'In-Year Saving (£)',
      cell: (info) => buildCurrency(info.getValue()),
    }),
    columnHelper.accessor((row) => row.next_year_saving, {
      id: 'next_year_saving',
      header: 'Carry Over (£)',
      cell: (info) => buildCurrency(info.getValue()),
    }),
    columnHelper.accessor('year_saving', {
      id: 'year_saving',
      header: '12-Month Saving (£)',
      cell: (cell) =>
        buildCurrency(
          cell.row.original.current_year_saving +
            cell.row.original.next_year_saving,
        ),
    }),
    columnHelper.accessor((row) => row.cip_ref, {
      id: 'cip_ref',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.is_recurrent, {
      id: 'is_recurrent',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildBoolean(info.getValue()),
    }),
    columnHelper.accessor((row) => row.approved_by, {
      id: 'approved_by',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.used_pd, {
      id: 'used_pd',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildBoolean(info.getValue()),
    }),
    columnHelper.accessor((row) => row.date_created, {
      id: 'date_created',
      header: 'Creation Date',
      cell: (info) => buildDate(info.getValue()),
    }),
    columnHelper.accessor((row) => row.notes, {
      id: 'notes',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildHistoryNotes(info),
    }),
  ];

  const table = useReactTable({
    data: projectHistory,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <div className="flex w-maxFull px-2 py-1 items-center justify-between bg-platform-ainc-grey-400 border border-platform-secondary-grey-200">
        <div className="flex-grow" />
        <div className="flex">
          <CsvExportHistoryButton
            projectHistory={projectHistory}
            formLayout={formLayout}
          />
        </div>
      </div>

      <div className="overflow-auto flex-col">
        <table className="w-full text-sm text-left text-platform-primary-grey-800">
          <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="py-1 px-3">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className="bg-white hover:bg-platform-ainc-grey-400"
                key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="py-1 px-3">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
