import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HiDocumentAdd,
  HiDocumentReport,
  HiDocumentSearch,
  HiHome,
  HiLogout,
} from 'react-icons/hi';
import Logo from '../assets/logo.png';

export default function TopBar({ setUser }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    setUser(null);
    sessionStorage.clear();
    navigate(`/login`);
  };

  return (
    <div className="flex items-center justify-between h-12 m-2">
      <div className="mx-2">
        <img src={Logo} alt="" width="170px" />
      </div>
      <div className="flex">
        <button
          type="button"
          onClick={() => navigate('/home')}
          className="flex px-3 py-3 mx-1 align-middle items-center text-sm rounded-full font-medium bg-white text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          Home <HiHome className="text-xl ml-1" />
        </button>
        <button
          type="button"
          onClick={() => navigate('/new')}
          className="flex px-3 py-3 mx-1 align-middle items-center text-sm rounded-full font-medium bg-white text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          Create <HiDocumentAdd className="text-xl ml-1" />
        </button>
        <button
          type="button"
          onClick={() => navigate('/projects')}
          className="flex px-3 py-3 mx-1 align-middle items-center text-sm rounded-full font-medium bg-white text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          Search <HiDocumentSearch className="text-xl ml-1" />
        </button>
        <button
          type="button"
          onClick={() => navigate('/analytics')}
          className="flex px-3 py-3 mx-1 align-middle items-center text-sm rounded-full font-medium bg-white text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          Analytics <HiDocumentReport className="text-xl ml-1" />
        </button>
        <button
          type="button"
          onClick={() => handleLogout()}
          className="flex px-3 py-3 mx-1 align-middle items-center text-sm rounded-full font-medium bg-white text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
          Logout <HiLogout className="text-xl ml-1" />
        </button>
      </div>
    </div>
  );
}
