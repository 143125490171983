import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import CryptoES from 'crypto-es';
import * as Label from '@radix-ui/react-label';
import { baseUrl, headers } from '../../app/lookups';
import AdviseWhite from '../../assets/AdviseInc_Logo_WHITE.png';

function Login({ setUser }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [displayWarning, setDisplayWarning] = useState(false);

  const navigate = useNavigate();

  const salt = 'saltsaltsalt';

  const handleLogin = useCallback(() => {
    const hashedPassword = CryptoES.MD5(salt.concat(password)).toString();
    axios
      .get(
        `${baseUrl}/workplan/login?email=${email}&password=${hashedPassword}`,
        {
          headers,
        },
      )
      .then((response) => {
        const loginResponse = JSON.parse(response.data.body);
        if (loginResponse.result === 'success') {
          setUser({
            name: loginResponse.name,
            ics: loginResponse.ics,
          });
          sessionStorage.setItem('name', loginResponse.name);
          sessionStorage.setItem('ics', loginResponse.ics);
          navigate('/home');
        } else {
          setUser(null);
          setDisplayWarning(true);
        }
      });
  }, [email, navigate, password, setUser]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleLogin]);

  return (
    <div className="font-main min-h-screen flex">
      <img
        src={AdviseWhite}
        className="absolute z-20 w-150 top-5 left-5"
        alt=""
      />
      <div className="bg-hero bg-no-repeat bg-cover bg-center flex-1 flex flex-col justify-center w-1/2 z-0">
        <div className="p-10 text-center font-extrabold text-6xl text-white tracking-tight leading-snug">
          Helping Healthcare <p className="italic">#MakeSense</p> of Spend Data
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-center w-1/2 text-center bg-white">
        <div className="mx-auto w-full max-w-lg p-3 bg-white rounded-lg">
          <div className="pb-4">
            <div className="text-5xl font-bold text-platform-primary-grey-800 pb-1">
              Welcome to Workplan
            </div>
            <span>Please fill in your details</span>
          </div>
          <div className="flex flex-col">
            <div className="flex-col items-center justify-center mb-6">
              <div className="pb-2">
                <Label.Root
                  className="block uppercase tracking-wide text-left text-platform-primary-grey-800 text-xs font-bold mb-2"
                  htmlFor="email">
                  Email
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      email === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Label.Root>
              </div>
              <div className="pb-2">
                <Label.Root
                  className="block uppercase tracking-wide text-left text-platform-primary-grey-800 text-xs font-bold mb-2"
                  htmlFor="password">
                  Password
                  <input
                    className={`appearance-none block w-maxFull py-3 px-4 leading-tight rounded-md border ${
                      password === ''
                        ? 'border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Label.Root>
              </div>
              {displayWarning ? (
                <div className="py-2">
                  <Alert
                    severity="error"
                    onClose={() => setDisplayWarning(false)}>
                    <AlertTitle>
                      <strong>Login attempt failed.</strong>
                    </AlertTitle>
                    Please check your credentials.
                  </Alert>
                </div>
              ) : null}
              <button
                onClick={handleLogin}
                type="button"
                disabled={email === null || password === null}
                className="w-full px-3 py-3 bg-white border-platform-primary-orange-800 border-2 rounded-md p-2 min-w-full text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
