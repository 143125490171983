import React from 'react';
import Chart from 'react-apexcharts';

function PieChart({ seriesValues, seriesLabels, title }) {
  const chartProps = {
    series: seriesValues,
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true,
        },
      },
      labels: seriesLabels,
      title: {
        text: title,
        align: 'left',
      },
      fill: {
        type: 'gradient',
      },
      colors: ['#D6001C', '#F2A900', '#E87722'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <Chart options={chartProps.options} series={chartProps.series} type="pie" />
  );
}

export default PieChart;
