import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { MdFilterAltOff } from 'react-icons/md';

export default function ClearFiltersButton({ clearFilters }) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            aria-label="clear-filters-button"
            className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-xs leading-0.1 justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400"
            type="button"
            id="clear-filters-button"
            onClick={() => clearFilters([])}>
            <MdFilterAltOff className="text-xl" />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
            Clear All Filters
            <Tooltip.Arrow className="fill-platform-primary-grey-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
