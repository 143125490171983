import React, { useState } from 'react';
import { HiChevronLeft, HiHome, HiInformationCircle } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import CreateNewProject from './CreateNewProject';
import Glossary from './Glossary';

export default function CreateNewProjectPage() {
  const navigate = useNavigate();
  const [glossaryOpen, setGlossaryOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex px-2 py-1 h-[40px] drop-shadow-lg items-center justify-between bg-platform-ainc-grey-400 border border-platform-secondary-grey-200">
        <button
          type="button"
          aria-label="home-page-button"
          onClick={() => navigate('/home')}
          className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
          <div className="flex items-center justify-between">
            <HiChevronLeft className="text-2xl" /> Home{' '}
            <HiHome className="text-xl ml-1" />
          </div>
        </button>
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                type="button"
                aria-label="information-button"
                onClick={() => setGlossaryOpen(!glossaryOpen)}
                className="mx-1 my-0.5 p-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <span>
                  <HiInformationCircle className="text-xl" />
                </span>
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Glossary
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex max-w-5xl p-2">
          <div className="rounded-lg shadow-lg flex flex-col w-full bg-white border border-platform-primary-grey-200">
            <CreateNewProject />
          </div>
        </div>
      </div>
      <Glossary glossaryOpen={glossaryOpen} setGlossaryOpen={setGlossaryOpen} />
    </div>
  );
}
