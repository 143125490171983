import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HiChevronLeft, HiHome } from 'react-icons/hi';
import { baseUrl, headers } from '../../app/lookups';
import Loader from '../../components/loader/Loader';
import PieChart from './PieChart';
import LineChart from './LineChart';

export default function Analytics() {
  const [analytics, setAnalytics] = useState(null);

  const ics = sessionStorage.getItem('ics');

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${baseUrl}/workplan/analytics?ics=${ics}`, { headers })
      .then((response) => {
        setAnalytics(response.data);
      });
  }, [ics]);

  if (analytics === null) {
    return <Loader fullscreen />;
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="flex px-2 py-1 h-[40px] drop-shadow-lg items-center bg-platform-ainc-grey-400 border border-platform-secondary-grey-200">
        <button
          type="button"
          onClick={() => navigate('/home')}
          className="mx-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
          <div className="flex items-center justify-between">
            <HiChevronLeft className="text-2xl" /> Home{' '}
            <HiHome className="text-xl ml-1" />
          </div>
        </button>
      </div>
      <div className="flex justify-center items-center">
        <div className="p-6 m-2 border w-maxFull">
          <PieChart
            seriesValues={analytics.totals_by_trust.map((x) =>
              Math.floor(parseFloat(x.sum)),
            )}
            seriesLabels={analytics.totals_by_trust.map((x) => x.name)}
            title="Total 12-Monthly Saving By Trust (£)"
          />
        </div>
        <div className="m-2 border w-maxFull">
          <LineChart
            seriesData={[
              {
                name: 'Delivered',
                data: analytics.monthly_targets.map((x) =>
                  parseFloat(x.cum_delivered).toFixed(2),
                ),
              },
              {
                name: 'Potential',
                data: analytics.monthly_targets.map((x) =>
                  parseFloat(x.cum_potential).toFixed(2),
                ),
              },
              {
                name: 'Total',
                data: analytics.monthly_targets.map((x) =>
                  (
                    parseFloat(x.cum_delivered) + parseFloat(x.cum_potential)
                  ).toFixed(2),
                ),
              },
            ]}
            categories={analytics.monthly_targets.map((x) => x.month)}
            title="Month-By-Month Targets"
          />
        </div>
      </div>
    </div>
  );
}
