import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { CSVLink } from 'react-csv';
import { HiDocumentDownload } from 'react-icons/hi';
import dateFormat from 'dateformat';
import { useAPI } from '../../app/apiContext';

export default function CsvExportHistoryButton({ projectHistory, formLayout }) {
  const [csvData, setCsvData] = useState([]);
  const { cipCategories } = useAPI();

  const csvOptions = {
    cipCategories,
  };

  const csvHeaders = [
    { label: 'Action', key: 'action' },
    { label: 'Date Last Updated', key: 'date_updated' },
    { label: 'Project Title', key: 'title' },
    { label: 'Status', key: 'status' },
    { label: 'Trust', key: 'trust' },
    { label: 'Project Lead', key: 'project_lead' },
    { label: 'Division', key: 'division' },
    { label: 'Department', key: 'dept' },
    { label: 'Project Ref', key: 'project_ref' },
    { label: 'Anticipated Delivery', key: 'anticipated_delivery' },
    { label: 'Actual Delivery', key: 'actual_delivery' },
    { label: 'Total Contract Value (£)', key: 'contract_value' },
    { label: 'CIP Category', key: 'cip_category' },
    { label: 'Savings (£)', key: 'cip_target' },
    { label: 'In-Year Saving (£)', key: 'current_year_saving' },
    { label: 'Carry Over Savings (£)', key: 'next_year_saving' },
    { label: '12-Month Saving (£)', key: 'year_saving' },
    { label: 'CIP Tracking Ref', key: 'cip_ref' },
    { label: 'Recurring?', key: 'is_recurrent' },
    { label: 'CIP Approved By', key: 'approved_by' },
    { label: 'Used AdviseInc Services?', key: 'used_pd' },
    { label: 'Notes', key: 'notes' },
  ];

  const formatField = (project, field) => {
    if (field.component === 'currency') {
      return (project[field.id] / 100).toFixed(2).toLocaleString();
    }
    if (field.component === 'date') {
      return dateFormat(project[field.id], 'dd/mm/yy');
    }

    if (field.component === 'select' && field.options === 'cipCategories') {
      const selectedOption = csvOptions[field.options]
        .filter((option) => option.id === project[field.id])
        .map((option) => option.name)
        .toString();
      return selectedOption;
    }
    return project[field.id];
  };

  const transformProject = (project) => {
    const flattenedFields = formLayout
      .flatMap((page) => page.rows.flatMap((row) => row.fields))
      .map((field) => ({
        id: field.uid,
        component: field.component,
        options: 'options' in field ? field.options : null,
      }));

    const transformedData = flattenedFields.reduce((obj, item) => {
      const formattedValue = formatField(project, item);
      return {
        ...obj,
        [item.id]: formattedValue,
      };
    }, {});
    return {
      ...transformedData,
      year_saving: (
        (project.current_year_saving + project.next_year_saving) /
        100
      )
        .toFixed(2)
        .toLocaleString(),
      date_updated: dateFormat(project.date_updated, 'dd/mm/yy'),
      current_year_saving: (project.current_year_saving / 100)
        .toFixed(2)
        .toLocaleString(),
      next_year_saving: (project.next_year_saving / 100)
        .toFixed(2)
        .toLocaleString(),
      action: project.action,
      id: project.id,
      anticipated_delivery: project.anticipated_delivery || '',
      actual_delivery: project.actual_delivery || '',
    };
  };

  const handleCsvClick = () => {
    const projectHistoryMapped = projectHistory.map((project) =>
      transformProject(project),
    );
    setCsvData(projectHistoryMapped);
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div>
            <CSVLink
              data={csvData}
              data-testid="history-export"
              asyncOnClick={false}
              onClick={() => {
                handleCsvClick();
              }}
              headers={csvHeaders}
              filename={`workplan-projects-history-${new Date()
                .toISOString()
                .split('T')[0]
                .replace(/-/g, '')}.csv`}>
              <div className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiDocumentDownload className="text-xl" />
              </div>
            </CSVLink>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
            Export Projects History
            <Tooltip.Arrow className="fill-platform-primary-grey-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
