import React, { useEffect, useState, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../features/login/Login';
import Loader from '../components/loader/Loader';
import Home from '../features/home/Home';
import PageLayout from '../components/PageLayout';
import CreateNewProjectPage from '../features/add-project/CreateNewProjectPage';
import Analytics from '../features/analytics/Analytics';
import Projects from '../features/projects-table/Projects';
import ContextLayout from '../components/ContextLayout';

function App() {
  const [user, setUser] = useState(null);

  // Allow users to remain logged in on refresh
  useEffect(() => {
    const isLoggedIn = !!(
      sessionStorage.getItem('name') && sessionStorage.getItem('ics')
    );
    if (isLoggedIn) {
      setUser({
        name: sessionStorage.getItem('name'),
        ics: sessionStorage.getItem('ics'),
      });
    }
  }, []);

  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Routes>
        <Route path="/login" index element={<Login setUser={setUser} />} />
        <Route
          path="/"
          element={
            user ? (
              <PageLayout setUser={setUser} />
            ) : (
              <Login setUser={setUser} />
            )
          }>
          <Route element={<ContextLayout />}>
            <Route
              path="/home"
              element={
                user ? <Home user={user} /> : <Login setUser={setUser} />
              }
            />
            <Route
              path="/new"
              element={
                user ? <CreateNewProjectPage /> : <Login setUser={setUser} />
              }
            />
            <Route
              path="/projects"
              element={
                user ? <Projects user={user} /> : <Login setUser={setUser} />
              }
            />
            <Route
              path="/analytics"
              element={user ? <Analytics /> : <Login setUser={setUser} />}
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
