export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_API_BASE_URL;

// baseUrl for development using dev endpoint
// export const baseUrl =
//   'https://k7x872f1a6.execute-api.eu-west-1.amazonaws.com/v1';

export const headers = {
  'Content-Type': 'application/json',
  authorization: 'allow',
  'Access-Control-Allow-Origin': 'True',
};

export const status = [
  { id: 'Potential', name: 'Potential', priority: 1 },
  // { id: 'Planned', name: 'Planned', priority: 2 },
  { id: 'Delivered', name: 'Delivered', priority: 3 },
  { id: 'Blocked', name: 'Blocked', priority: 4 },
];

export const atamisExportFields = [
  { label: 'Workplan Name', id: 'title' },
  { label: 'Contracting Authority', id: 'trust' },
  { label: 'Business Unit', id: '' },
  { label: 'Business Unit Contact', id: '' },
  { label: 'Cost Centre', id: '' },
  { label: 'When is work required to start', id: '' },
  { label: 'Existing Supplier', id: '' },
  { label: 'Existing Supplier Check', id: '' },
  {
    label: 'Value of new contract (ex VAT & options)',
    id: 'twelve_monthly_saving',
  },
  { label: 'Short Description', id: 'title' },
  { label: 'Commercial Lead', id: 'project_lead' },
  { label: 'Commercial Strategy', id: '' },
  { label: 'Expected Contract Commencement Date', id: 'anticipated_delivery' },
  { label: 'Contract Duration (Months)', id: '' },
  { label: 'Personal Confidential Data?', id: '' },
  { label: 'DPIA Assessment', id: '' },
  { label: 'Government spending controls', id: '' },
  { label: 'Current Workplan Stage', id: '' },
  { label: 'Existing Contract end date', id: '' },
  { label: 'Procurement Start date', id: 'start_date' },
  { label: 'Expected date for OBC', id: '' },
  { label: 'Expected date for FBC', id: '' },
  { label: 'Level of Competition', id: '' },
  { label: 'CM Classification', id: '' },
  { label: 'Primary CPV Category', id: '' },
  { label: 'Primary Alternative Category', id: '' },
  { label: 'Category Manager', id: 'project_lead' },
  { label: 'Procurement Sourcing Route', id: '' },
  { label: 'Procurement Delivery Lead', id: 'project_lead' },
  { label: 'Route to Market (Expected / Chosen)', id: '' },
  { label: 'Variation Reference', id: '' },
  { label: 'New Framework / DPS?', id: '' },
  { label: 'Expected Framework/DPS to be used', id: '' },
  { label: 'Expected Framework/DPS to be used *Other', id: '' },
  { label: 'PSBC reference No.', id: '' },
  { label: 'Legal ticket number', id: '' },
  { label: 'External Workplan ID', id: 'project_ref' },
  { label: 'Delivery scale', id: '' },
  { label: 'Commercial RAG', id: '' },
  { label: 'Previous commercial RAG', id: '' },
  { label: 'Commercial RAG Management Summary', id: '' },
  { label: 'Commercial Notes (Short)', id: '' },
  { label: 'Commercial Notes (Long)', id: 'notes' },
  { label: 'Management summary roll-up', id: '' },
  { label: 'Complexity', id: '' },
  { label: 'Effort', id: '' },
  { label: 'Work Priority', id: '' },
  { label: 'Planned Savings Start Date', id: 'anticipated_delivery' },
  { label: 'Planned In Year Savings', id: 'current_year_saving' },
  { label: 'Planned Full Year Savings', id: 'twelve_monthly_saving' },
  { label: 'Financial Year', id: '' },
  { label: 'Non-Pay / Income / Pay', id: '' },
  { label: 'Cash Releasing / Non-Cash Releasing', id: '' },
  { label: 'Recurrent / Non-Recurrent', id: 'is_recurrent' },
  { label: 'Revenue / Capital', id: '' },
  { label: 'Savings Plan Risk', id: 'cip_category' },
  { label: '[External System id 1]', id: 'cip_ref' },
  { label: '[External System id 2]', id: 'actual_delivery' },
  { label: '[External System id 3]', id: 'cip_target' },
  { label: '[External System id 4]', id: 'next_year_saving' },
  { label: '[External System id 5]', id: 'used_pd' },
  { label: '[External System id 6]', id: 'status' },
  { label: '[External System id 7]', id: 'project_id' },
  { label: '[Add more as required]', id: '' },
  { label: 'Current Workplan Stage', id: '' },
  { label: 'Business Unit', id: '' },
  { label: 'Business Unit', id: '' },
  { label: 'Contracting Authority', id: '' },
];

export const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const weekdayNamesShort = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];
export const routeToMarket = [
  { id: 'Call-off – Mini Competition', name: 'Call-off – Mini Competition' },
  { id: 'Call-off – Direct Award', name: 'Call-off – Direct Award' },
  {
    id: 'Call-off from a dynamic purchasing agreement',
    name: 'Call-off from a dynamic purchasing agreement',
  },
  { id: 'Under threshold – Open', name: 'Under threshold – Open' },
  { id: 'Above threshold – Open', name: 'Above threshold – Open' },
  { id: 'Above threshold – Restricted', name: 'Above threshold – Restricted' },
  {
    id: 'Above threshold – Competitive Dialogue',
    name: 'Above threshold – Competitive Dialogue',
  },
  {
    id: 'Above threshold – Competitive procedure with negotiation',
    name: 'Above threshold – Competitive procedure with negotiation',
  },
  {
    id: 'Above threshold – Innovation Partnership',
    name: 'Above threshold – Innovation Partnership',
  },
  { id: 'Single Tender Action', name: 'Single Tender Action' },
  { id: 'MoU', name: 'MoU' },
  { id: 'Collaboration Agreement', name: 'Collaboration Agreement' },
  {
    id: 'PoSA (Provision of Services Agreement)',
    name: 'PoSA (Provision of Services Agreement)',
  },
  { id: 'Work Package (PoSA)', name: 'Work Package (PoSA)' },
];

export const commercialStrategy = [
  { id: '1. New requirement', name: '1. New requirement' },
  {
    id: '2. Re-procurement (same scope)',
    name: '2. Re-procurement (same scope)',
  },
  {
    id: '3. Re-procurement (different scope)',
    name: '3. Re-procurement (different scope)',
  },
  { id: '4. Extension (in contract)', name: '4. Extension (in contract)' },
  { id: '5. Contract variation', name: '5. Contract variation' },
  { id: '97. Commercial Policy Advice', name: '97. Commercial Policy Advice' },
  {
    id: '98. New Requirement (Statement of Work)',
    name: '98. New Requirement (Statement of Work)',
  },
  { id: '99. Commercial Assurance', name: '99. Commercial Assurance' },
];

export const cmClassification = [
  { id: 'Transactional', name: 'Transactional' },
  { id: 'Bronze', name: 'Bronze' },
  { id: 'Silver', name: 'Silver' },
  { id: 'Gold', name: 'Gold' },
];

export const savingsTypes = [
  { id: 'Cashable Saving', name: 'Cashable Saving' },
  { id: 'Non-Cashable Saving', name: 'Non-Cashable Saving' },
];
export const savingsCategories = [
  {
    savings_type: 'Cashable Saving',
    id: 'A - Price Reduction',
    name: 'A - Price Reduction',
  },
  { savings_type: 'Cashable Saving', id: 'B - Rebates', name: 'B - Rebates' },
  {
    savings_type: 'Cashable Saving',
    id: 'C - Volume Discount/Reductions',
    name: 'C - Volume Discount/Reductions',
  },
  {
    savings_type: 'Cashable Saving',
    id: 'D - Income Generation',
    name: 'D - Income Generation',
  },
  {
    savings_type: 'Cashable Saving',
    id: 'E - Cancellation',
    name: 'E - Cancellation',
  },
  {
    savings_type: 'Cashable Saving',
    id: 'F - Other cashable saving (free text appear)',
    name: 'F - Other cashable saving (free text appear)',
  },
  {
    savings_type: 'Cashable Saving',
    id: 'N - Cost Mitigation',
    name: 'N - Cost Mitigation',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'G - Cost Avoidance',
    name: 'G - Cost Avoidance',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'H - Reduction in Budget Spend',
    name: 'H - Reduction in Budget Spend',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'I - Added Value (Non-cashable)',
    name: 'I - Added Value (Non-cashable)',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'J - Reduce Adverse Drug Events',
    name: 'J - Reduce Adverse Drug Events',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'K - Enhanced Reimbursement',
    name: 'K - Enhanced Reimbursement',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'L - Quality Enhancement',
    name: 'L - Quality Enhancement',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'M - Other non-cashable',
    name: 'M - Other non-cashable',
  },
  {
    savings_type: 'Non-Cashable Saving',
    id: 'N - Cost Mitigation',
    name: 'N - Cost Mitigation',
  },
];

export const contractTypes = [
  { id: 'Catalogue', name: 'Catalogue' },
  { id: 'Collaboration Agreement', name: 'Collaboration Agreement' },
  { id: 'Contract', name: 'Contract' },
  { id: 'Deed', name: 'Deed' },
  { id: 'Donation Agreement', name: 'Donation Agreement' },
  { id: 'Framework Agreement', name: 'Framework Agreement' },
  { id: 'Framework Call Off Contract', name: 'Framework Call Off Contract' },
  { id: 'Grant', name: 'Grant' },
  { id: 'Income Agreement', name: 'Income Agreement' },
  { id: 'Lease', name: 'Lease' },
  { id: 'Letter of Intent (LOI)', name: 'Letter of Intent (LOI)' },
  { id: 'Licence', name: 'Licence' },
  { id: 'Master Indemnity Agreement', name: 'Master Indemnity Agreement' },
  {
    id: 'Memorandum of Understanding (MoU)',
    name: 'Memorandum of Understanding (MoU)',
  },
  {
    id: 'Non Disclosure Agreement (NDA)',
    name: 'Non Disclosure Agreement (NDA)',
  },
  { id: 'Novation', name: 'Novation' },
  {
    id: 'Provision of Services Agreement (POSA)',
    name: 'Provision of Services Agreement (POSA)',
  },
  { id: 'Purchase Order Agreement', name: 'Purchase Order Agreement' },
  { id: 'Research Contract', name: 'Research Contract' },
  {
    id: 'Service Level Agreement (SLA)',
    name: 'Service Level Agreement (SLA)',
  },
  { id: 'Variation Agreement', name: 'Variation Agreement' },
];

export const publicProcurementRegulations = [
  {
    id: 'Public Contracts Regulations 2015',
    name: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Provider Selection Regime 2023',
    name: 'Provider Selection Regime 2023',
  },
  { id: 'Procurement Act 2023', name: 'Procurement Act 2023' },
  {
    id: 'Not Applicable (Non Procurement)',
    name: 'Not Applicable (Non Procurement)',
  },
];

export const procurementRoutes = [
  // Procurement Act 2023 routes
  {
    id: 'PA23 Open Procedure',
    name: 'PA23 Open Procedure',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Competitive Flexible Procedure',
    name: 'PA23 Competitive Flexible Procedure',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Direct Award',
    name: 'PA23 Direct Award',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Open or Competitive Flexible Procedure – New Framework',
    name: 'PA23 Open or Competitive Flexible Procedure – New Framework',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Framework - Further Competition',
    name: 'PA23 Framework - Further Competition',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Framework – Award Without Competition',
    name: 'PA23 Framework – Award Without Competition',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Establishing a Dynamic Market',
    name: 'PA23 Establishing a Dynamic Market',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Competitive award by reference to a Dynamic Market',
    name: 'PA23 Competitive award by reference to a Dynamic Market',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Below Threshold Open Competition',
    name: 'PA23 Below Threshold Open Competition',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Below Threshold Direct Award',
    name: 'PA23 Below Threshold Direct Award',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Light Touch Regime',
    name: 'PA23 Light Touch Regime',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Exemption - In-house',
    name: 'PA23 Exemption - In-house',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Exemption - Research',
    name: 'PA23 Exemption - Research',
    publicProcurementRegulations: 'Procurement Act 2023',
  },
  {
    id: 'PA23 Exemption - Other',
    name: 'PA23 Exemption - Other',
    publicProcurementRegulations: 'Procurement Act 2023',
  },

  // Public Contracts Regulations 2015 routes
  {
    id: 'Below Threshold - 3 Quotes',
    name: 'Below Threshold - 3 Quotes',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Below Threshold - Competitive Tender',
    name: 'Below Threshold - Competitive Tender',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Open Procedure',
    name: 'Open Procedure',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Competitive Dialogue (CD)',
    name: 'Competitive Dialogue (CD)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Competitive Procedure with Negotiation (CPN)',
    name: 'Competitive Procedure with Negotiation (CPN)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Direct Award - Below Threshold',
    name: 'Direct Award - Below Threshold',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Direct award (Reg 32) - negotiated procedure without prior publication',
    name: 'Direct award (Reg 32) - negotiated procedure without prior publication',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Dynamic Purchasing System (DPS)',
    name: 'Dynamic Purchasing System (DPS)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Dynamic Purchasing System Call-Off Contract',
    name: 'Dynamic Purchasing System Call-Off Contract',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR 2015 Framework - Direct Award',
    name: 'PCR 2015 Framework - Direct Award',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR 2015 Framework - Further Competition',
    name: 'PCR 2015 Framework - Further Competition',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR 2015 Framework - Set up of new Framework',
    name: 'PCR 2015 Framework - Set up of new Framework',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR Exemption - In-House (R12)',
    name: 'PCR Exemption - In-House (R12)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR Exemption - Research (R14)',
    name: 'PCR Exemption - Research (R14)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'PCR Exemption - Other',
    name: 'PCR Exemption - Other',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Innovation Partnership (IP)',
    name: 'Innovation Partnership (IP)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Light Touch Regime (LTR)',
    name: 'Light Touch Regime (LTR)',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },
  {
    id: 'Restricted Procedure',
    name: 'Restricted Procedure',
    publicProcurementRegulations: 'Public Contracts Regulations 2015',
  },

  // Provider Selection Regime 2023 routes
  {
    id: 'PSR Competitive Process',
    name: 'PSR Competitive Process',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Direct Award - Process A',
    name: 'PSR Direct Award - Process A',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Direct Award - Process B',
    name: 'PSR Direct Award - Process B',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Direct Award - Process C',
    name: 'PSR Direct Award - Process C',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Framework - Direct Award',
    name: 'PSR Framework - Direct Award',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Framework - Further Competition',
    name: 'PSR Framework - Further Competition',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Competitive Process - New Framework',
    name: 'PSR Competitive Process - New Framework',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Most Suitable Provider',
    name: 'PSR Most Suitable Provider',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },
  {
    id: 'PSR Urgent Award',
    name: 'PSR Urgent Award',
    publicProcurementRegulations: 'Provider Selection Regime 2023',
  },

  // Not Applicable (Non Procurement) routes
  {
    id: 'Non Procurement',
    name: 'Non Procurement',
    publicProcurementRegulations: 'Not Applicable (Non Procurement)',
  },
];
