import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';
import Footer from './Footer';

export default function PageLayout({ setUser }) {
  return (
    <div className="flex flex-col w-maxFull max-h-screen">
      <TopBar setUser={setUser} />
      <Outlet />
      <Footer />
    </div>
  );
}
