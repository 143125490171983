import React, { forwardRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';
import axios from 'axios';
import { baseUrl, headers } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';
import { useAPI } from '../../app/apiContext';

const DeleteProjectModal = forwardRef(({ setShowModal, project }, ref) => {
  const [deleteProjectResponse, setDeleteProjectResponse] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);

  const { fetchAllProjects } = useAPI();

  const handleOnSubmit = async () => {
    const responseData = await axios.delete(
      `${baseUrl}/workplan/project?project_id=${project.id}&atamis_workplan_id=${project?.atamis_workplan_id}`,
      { headers },
    );
    fetchAllProjects();
    setDisplayAlert(true);
    setDeleteProjectResponse(responseData.data);
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[60vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
              <div className="text-3xl font-bold text-platform-primary-grey-800">
                Delete Project
              </div>
              <button
                type="button"
                aria-label="delete-project-button"
                className="bg-white border-platform-cta-error-800 border-2 rounded-md p-2 text-platform-cta-error-800 font-bold hover:bg-platform-cta-error-600 hover:text-platform-cta-error-200 focus:bg-platform-cta-error-800 focus:text-white"
                onClick={() => setShowModal(false)}>
                <HiX />
              </button>
            </div>
            <div className="relative p-6 flex-auto max-h-[22rem] overflow-y-scroll">
              {`Project '${project.title}' will be deleted. This process is irreversible. Do you wish to continue?`}
            </div>
            <div className="p-6 border-t border-solid border-platform-primary-grey-200 rounded-b">
              {displayAlert && deleteProjectResponse.status === 'success' ? (
                <AlertMessage
                  success
                  message="Project has been deleted."
                  closeAlert={() => setDisplayAlert(false)}
                />
              ) : displayAlert ? (
                <AlertMessage
                  success={false}
                  message="Project deletion failed"
                  closeAlert={() => setDisplayAlert(false)}
                />
              ) : null}
              <div className="flex justify-end">
                <button
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="button"
                  onClick={
                    deleteProjectResponse &&
                    deleteProjectResponse.status === 'success'
                      ? () => setShowModal(false)
                      : () => handleOnSubmit()
                  }>
                  {deleteProjectResponse &&
                  deleteProjectResponse.status === 'success'
                    ? 'Back'
                    : 'Delete Project'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default DeleteProjectModal;
