import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { useCombobox } from 'downshift';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Controller } from 'react-hook-form';
import { List } from 'react-virtualized';

const ComboBox = forwardRef(
  (
    {
      control,
      width,
      options,
      fieldId,
      setValue,
      trigger,
      watch,
      mandatory,
      disabled,
      resetField,
      childField,
      rowHeight,
      value = null,
      listWidth = 250,
    },
    ref,
  ) => {
    const [items, setItems] = useState(options);
    const [selectedItem, setSelectedItem] = useState(null);
    const getOptionsFilter = (inputValue) => {
      const lowerCasedInputValue = inputValue.toLowerCase();

      return function optionsFilter(option) {
        return (
          !inputValue ||
          option.name.toLowerCase().includes(lowerCasedInputValue)
        );
      };
    };

    useEffect(() => {
      setItems(options);
    }, [options]);

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectItem,
    } = useCombobox({
      initialInputValue: value || '',
      onInputValueChange({ inputValue }) {
        setItems(
          inputValue === ''
            ? options
            : options.filter(getOptionsFilter(inputValue)),
        );
        if (inputValue === '') {
          setSelectedItem('');
          setValue(fieldId, '');
        }
      },
      items,
      itemToString(item) {
        return item ? item.name : '';
      },
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
        setSelectedItem(newSelectedItem);
        setValue(fieldId, newSelectedItem !== '' ? newSelectedItem.id : '');
        if (childField !== null) {
          resetField(childField);
        }
        trigger(fieldId);
      },
    });

    const watchedItem = watch(fieldId);

    useEffect(() => {
      if (watch(fieldId) === '') {
        selectItem('');
      }
    }, [fieldId, selectItem, watch, watchedItem]);

    const rowRenderer = useCallback(
      ({ index, key, style }) => {
        const item = items[index];
        return (
          <div
            key={key}
            style={style}
            className={`${
              highlightedIndex === index &&
              'bg-platform-primary-orange-800 text-white'
            }
    py-2 px-3
    ${selectedItem === item && 'font-bold shadow-sm flex flex-col'}`}
            {...getItemProps({ item, index })}>
            <span>{item.name}</span>
          </div>
        );
      },
      [highlightedIndex, selectedItem, getItemProps, items],
    );

    return (
      <div ref={ref}>
        <div className={`${width}`}>
          <Controller
            control={control}
            name={fieldId}
            rules={{ required: mandatory }}
            render={({ field }) => (
              <div className="flex group">
                <input
                  {...field}
                  disabled={disabled}
                  value={field.value || ''}
                  placeholder="Choose..."
                  className={`appearance-none block ${width} py-3 px-4 leading-tight rounded-s-md border-l border-t border-b ${
                    watch(fieldId) === ''
                      ? 'border-platform-primary-orange-800'
                      : 'border-platform-primary-grey-200'
                  }   bg-white text-xs font-medium text-left text-platform-primary-grey-800 group-hover:bg-platform-ainc-grey-400 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200 group-focus:border-platform-primary-orange-800 group-focus:ring-0 outline-none`}
                  {...getInputProps({
                    placeholder: 'Choose...',
                  })}
                />
                <button
                  {...field}
                  disabled={disabled}
                  className={`px-2 rounded-e-md border-r border-t border-b ${
                    watch(fieldId) === ''
                      ? 'border-platform-primary-orange-800'
                      : 'border-platform-primary-grey-200'
                  }  text-platform-primary-grey-800 group-hover:bg-platform-ainc-grey-400 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200 group-focus:border-platform-primary-orange-800 group-focus:ring-0 outline-none`}
                  type="button"
                  {...getToggleButtonProps()}>
                  {isOpen ? (
                    <HiChevronUp className="text-xl" />
                  ) : (
                    <HiChevronDown className="text-xl" />
                  )}
                </button>
              </div>
            )}
          />
        </div>
        <div
          className={`absolute bg-white max-h-80 overflow-y-auto rounded-md ${
            !(isOpen && options.length) && 'hidden'
          } border border-platform-primary-grey-800`}
          {...getMenuProps()}>
          {isOpen && (
            <List
              width={listWidth}
              height={
                items.length > 5 ? rowHeight * 4 : rowHeight * items.length
              }
              rowCount={items.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
            />
          )}
        </div>
      </div>
    );
  },
);

export default ComboBox;
