import React, { forwardRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import CreateNewProject from './CreateNewProject';

const CreateProjectModal = forwardRef(({ setShowModal }, ref) => (
  <div ref={ref}>
    <Dialog.Content className="w-[60vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
      <div className="flex justify-center items-center">
        <div className="flex max-w-5xl p-2">
          <div className="h-[90vh] overflow-y-scroll rounded-lg shadow-lg flex flex-col w-full bg-white border border-platform-primary-grey-200">
            <CreateNewProject setShowModal={setShowModal} />
          </div>
        </div>
      </div>
    </Dialog.Content>
  </div>
));

export default CreateProjectModal;
