import React from 'react';
import {
  HiChevronRight,
  HiDocumentAdd,
  HiDocumentReport,
  HiDocumentSearch,
} from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import HomeVideoGrid from './HomeVideoGrid';

export default function Home({ user }) {
  const navigate = useNavigate();

  return (
    <div className="h-screen">
      <div className="flex px-2 py-1 h-[40px] drop-shadow-lg items-center bg-platform-ainc-grey-400 border border-platform-secondary-grey-200" />
      <div className="flex-col max-w-full max-h-screen">
        <div className="flex items-center align-middle justify-center p-6 text-4xl text-platform-primary-grey-800">
          {`Hi ${user.name}, welcome to Workplan. What would you like to do?`}
        </div>
        <div className="flex flex-col justify-center items-center h-100">
          <div className="flex">
            <div className="p-6 m-3 w-[16rem] bg-white border border-platform-primary-grey-200 rounded-lg shadow">
              <div>
                <div className="mb-2 text-2xl font-bold tracking-tight text-platform-primary-grey-800 ">
                  Add A Project
                </div>
              </div>
              <p className="mb-3 font-normal text-platform-primary-grey-800">
                Store the details of a new project
              </p>
              <button
                type="button"
                onClick={() => navigate('/new')}
                className="flex justify-center m-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-2 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                <HiDocumentAdd className="text-xl mx-1" />
                Add A Project
                <HiChevronRight className="text-xl mx-1" />
              </button>
            </div>
            <div className="p-6 m-3 w-[16rem] bg-white border border-platform-primary-grey-200 rounded-lg shadow">
              <div>
                <div className="mb-2 text-2xl font-bold tracking-tight text-platform-primary-grey-800 ">
                  Search Projects
                </div>
              </div>
              <p className="mb-3 font-normal text-platform-primary-grey-800">
                View or make changes to your existing projects
              </p>
              <button
                type="button"
                onClick={() => navigate('/projects')}
                className="flex justify-center m-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-2 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                <HiDocumentSearch className="text-xl mx-1" />
                Search Projects
                <HiChevronRight className="text-xl" />
              </button>
            </div>
            <div className="p-6 m-3 w-[16rem] bg-white border border-platform-primary-grey-200 rounded-lg shadow">
              <div>
                <div className="mb-2 text-2xl font-bold tracking-tight text-platform-primary-grey-800 ">
                  View Analytics
                </div>
              </div>
              <p className="mb-3 font-normal text-platform-primary-grey-800">
                View statistical information about your projects
              </p>
              <button
                type="button"
                onClick={() => navigate('/analytics')}
                className="flex justify-center m-1 bg-platform-primary-orange-800 text-white text-sm font-bold px-4 py-2 rounded-full hover:bg-white hover:text-platform-primary-orange-800 hover:border-platform-primary-orange-800 border-1">
                <HiDocumentReport className="text-xl mx-1" />
                View Analytics
                <HiChevronRight className="text-xl mx-1" />
              </button>
            </div>
          </div>
          <HomeVideoGrid />
        </div>
      </div>
    </div>
  );
}
