import { React, forwardRef, useState, useEffect } from 'react';
import * as Label from '@radix-ui/react-label';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { HiLogin, HiLogout, HiRefresh } from 'react-icons/hi';
import { NumericFormat } from 'react-number-format';
import { add, format, getMonth, getYear, isValid, parse } from 'date-fns';
import { useWatch } from 'react-hook-form';

function TableCell({ getValue, row, column, table }) {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };

  return (
    <div className="flex justify-center items-center">
      <NumericFormat
        className="appearance-none block py-1 leading-tight rounded-md border border-platform-primary-grey-200 w-[7rem] bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
        thousandSeparator=","
        allowNegative={false}
        prefix="£"
        decimalScale={2}
        fixedDecimalScale
        value={parseFloat((value / 100).toFixed(2))}
        displayType="input"
        onValueChange={(values) => {
          setValue(values.floatValue * 100);
        }}
        onBlur={onBlur}
      />
    </div>
  );
}

const MonthlyBreakdown = forwardRef(
  ({ label, mandatory, fieldId, watch, setValue, control }, ref) => {
    const status = useWatch({
      control,
      name: 'status',
    });
    const anticipatedDelivery = useWatch({
      control,
      name: 'anticipatedDelivery',
    });
    const actualDelivery = useWatch({
      control,
      name: 'actualDelivery',
    });
    const cipTarget = useWatch({
      control,
      name: 'cipTarget',
    });

    const [showTable, setShowTable] = useState(false);

    const [numMonths, setNumMonths] = useState(
      watch('savingsByMonth').length === 0
        ? 12
        : watch('savingsByMonth').length,
    );

    const generateBreakdown = () => {
      const data = [];
      const startDate =
        status === 'Delivered'
          ? actualDelivery
          : status === 'Potential' || status === 'Planned'
          ? anticipatedDelivery
          : null;
      for (let i = 0; i < numMonths; i += 1) {
        const month = format(
          add(parse(startDate, 'dd/MM/yyyy', new Date()), {
            months: i,
          }),
          'MMM yyyy',
        );
        const value = parseFloat((cipTarget / numMonths).toFixed(2));
        data.push({ id: i.toString(), month, amount: value });
      }
      return data;
    };

    const allInFirst = () => {
      const data = [];
      const startDate =
        status === 'Delivered'
          ? actualDelivery
          : status === 'Potential' || status === 'Planned'
          ? anticipatedDelivery
          : null;
      for (let i = 0; i < numMonths; i += 1) {
        const month = format(
          add(parse(startDate, 'dd/MM/yyyy', new Date()), {
            months: i,
          }),
          'MMM yyyy',
        );
        const value =
          i === 0
            ? parseFloat(cipTarget.toFixed(2))
            : parseFloat((0.0).toFixed(2));
        data.push({ id: i.toString(), month, amount: value });
      }
      return data;
    };

    const allInLast = () => {
      const data = [];
      const startDate =
        status === 'Delivered'
          ? actualDelivery
          : status === 'Potential' || status === 'Planned'
          ? anticipatedDelivery
          : null;
      for (let i = 0; i < numMonths; i += 1) {
        const month = format(
          add(parse(startDate, 'dd/MM/yyyy', new Date()), {
            months: i,
          }),
          'MMM yyyy',
        );
        const value =
          i === numMonths - 1
            ? parseFloat(cipTarget.toFixed(2))
            : parseFloat((0.0).toFixed(2));
        data.push({ id: i.toString(), month, amount: value });
      }
      return data;
    };

    const [data, setData] = useState(
      watch(fieldId) !== '' && watch(fieldId) !== []
        ? watch(fieldId)
        : (status === 'Delivered' && actualDelivery !== null) ||
          ((status === 'Potential' || status === 'Planned') &&
            anticipatedDelivery !== null)
        ? generateBreakdown()
        : [],
    );

    // Only show table when a status and date are set
    useEffect(() => {
      setShowTable(
        (status === 'Delivered' &&
          actualDelivery !== null &&
          isValid(parse(actualDelivery, 'dd/MM/yyyy', new Date()))) ||
          ((status === 'Potential' || status === 'Planned') &&
            anticipatedDelivery !== null &&
            isValid(parse(anticipatedDelivery, 'dd/MM/yyyy', new Date()))),
      );
    }, [actualDelivery, anticipatedDelivery, status]);

    // Reset data array when no. of months changes
    useEffect(() => {
      if (data.length === 0 || numMonths !== data.length) {
        setData(
          (status === 'Delivered' &&
            actualDelivery !== null &&
            isValid(parse(actualDelivery, 'dd/MM/yyyy', new Date()))) ||
            ((status === 'Potential' || status === 'Planned') &&
              anticipatedDelivery !== null &&
              isValid(parse(anticipatedDelivery, 'dd/MM/yyyy', new Date())))
            ? generateBreakdown()
            : [],
        );
      } else {
        setData(
          (status === 'Delivered' && actualDelivery !== null) ||
            ((status === 'Potential' || status === 'Planned') &&
              anticipatedDelivery !== null)
            ? data
            : [],
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      actualDelivery,
      anticipatedDelivery,
      fieldId,
      status,
      numMonths,
      cipTarget,
    ]);

    // Update data array when date or saving amount changes
    useEffect(() => {
      setData(
        (status === 'Delivered' &&
          actualDelivery !== null &&
          isValid(parse(actualDelivery, 'dd/MM/yyyy', new Date()))) ||
          ((status === 'Potential' || status === 'Planned') &&
            anticipatedDelivery !== null &&
            isValid(parse(anticipatedDelivery, 'dd/MM/yyyy', new Date())))
          ? generateBreakdown()
          : [],
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldId, status, actualDelivery, anticipatedDelivery, cipTarget]);

    // Update in-year saving when tableEntries is changed
    useEffect(() => {
      const startDate =
        status === 'Delivered'
          ? actualDelivery
          : status === 'Potential' || status === 'Planned'
          ? anticipatedDelivery
          : null;
      const startMonth = getMonth(parse(startDate, 'dd/MM/yyyy', new Date()));
      const startYear = getYear(parse(startDate, 'dd/MM/yyyy', new Date()));
      const inYearEntries =
        startMonth < 3
          ? data.filter(
              (entry) =>
                (getMonth(parse(entry.month, 'MMM yyyy', new Date())) < 3 &&
                  getYear(parse(entry.month, 'MMM yyyy', new Date())) ===
                    startYear) ||
                (getMonth(parse(entry.month, 'MMM yyyy', new Date())) >= 3 &&
                  getYear(parse(entry.month, 'MMM yyyy', new Date())) <
                    startYear),
            )
          : data.filter(
              (entry) =>
                (getMonth(parse(entry.month, 'MMM yyyy', new Date())) >= 3 &&
                  getYear(parse(entry.month, 'MMM yyyy', new Date())) ===
                    startYear) ||
                (getMonth(parse(entry.month, 'MMM yyyy', new Date())) < 3 &&
                  getYear(parse(entry.month, 'MMM yyyy', new Date())) >
                    startYear),
            );
      setValue(
        'inYearSaving',
        inYearEntries
          .map((entry) => entry.amount)
          .reduce((partialSum, a) => partialSum + a, 0),
      );
    }, [actualDelivery, anticipatedDelivery, status, data, setValue]);

    // Update 12-monthly saving when tableEntries is changed
    useEffect(() => {
      setValue('carryOver', watch('cipTarget') - watch('inYearSaving'));
    }, [data, setValue, watch]);

    // Update form when data array changes
    useEffect(() => {
      setValue(fieldId, data);
    }, [data, fieldId, setValue]);

    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('month', {
        id: 'month',
        header: 'Month',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('amount', {
        id: 'amount',
        header: 'Saving Amount (£)',
        cell: TableCell,
      }),
    ];

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      meta: {
        updateData: (rowIndex, columnId, value) => {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        },
      },
    });

    return showTable ? (
      <div className="rounded-md border border-platform-primary-grey-200">
        <div className="flex justify-between  mx-2 mt-2 mb-3">
          <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
            {`${label} ${mandatory ? '*' : ''}`}
          </Label.Root>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col justify-center items-center w-[30vw]">
            <table className="text-sm text-left text-platform-primary-grey-800">
              <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="py-1 px-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    className="bg-white hover:bg-platform-ainc-grey-400"
                    key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-2 border">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className=" flex justify-center items-center bg-white hover:bg-platform-ainc-grey-400">
              <div>Add/remove months</div>
              <div className="px-2">
                {' '}
                <div className="flex items-center justify-center">
                  <button
                    id="remove-month"
                    type="button"
                    disabled={numMonths === 1}
                    onClick={() => setNumMonths(numMonths - 1)}
                    className=" mx-0.5 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                    <div className="mx-2 my-1"> - </div>
                  </button>
                  <button
                    id="add-month"
                    type="button"
                    onClick={() => setNumMonths(numMonths + 1)}
                    className="mx-0.5 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                    <div className="mx-2 my-1"> + </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center items-center">
            <div
              className={`${
                cipTarget !==
                  data
                    .map((t) => t.amount)
                    .reduce((partialSum, a) => partialSum + a, 0) &&
                'text-platform-cta-error-800'
              }`}>{`Total: £ ${(
              data
                .map((t) => t.amount)
                .reduce((partialSum, a) => partialSum + a, 0) / 100
            ).toFixed(2)}`}</div>
            <button
              type="button"
              className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
              onClick={() => {
                setData(allInFirst());
              }}>
              <div className="flex">
                All In First <HiLogin className="text-xl ml-1" />
              </div>
            </button>
            <button
              type="button"
              className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
              onClick={() => {
                setData(generateBreakdown());
              }}>
              <div className="flex">
                Even Split <HiRefresh className="text-xl ml-1" />
              </div>
            </button>
            <button
              type="button"
              className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
              onClick={() => {
                setData(allInLast());
              }}>
              <div className="flex">
                All In Last <HiLogout className="text-xl ml-1" />
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="mx-2 mt-2 mb-3">
            <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
              In-Year Saving
            </Label.Root>
            <NumericFormat
              getInputRef={ref}
              disabled
              className="appearance-none block py-3 px-4 leading-tight rounded-md border border-platform-primary-grey-200 w-[16rem] bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
              thousandSeparator=","
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={watch('inYearSaving') / 100}
              displayType="input"
            />
          </div>
          <div className="mx-2 mt-2 mb-3">
            <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
              Carry Over
            </Label.Root>
            <NumericFormat
              getInputRef={ref}
              disabled
              className="appearance-none block py-3 px-4 leading-tight rounded-md border border-platform-primary-grey-200 w-[16rem] bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
              thousandSeparator=","
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={watch('carryOver') / 100}
              displayType="input"
            />
          </div>
          <div className="mx-2 mt-2 mb-3">
            <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
              12-Monthly Saving
            </Label.Root>
            <NumericFormat
              getInputRef={ref}
              disabled
              className="appearance-none block py-3 px-4 leading-tight rounded-md border border-platform-primary-grey-200 w-[16rem] bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
              thousandSeparator=","
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={
                data
                  .slice(0, 12)
                  .map((entry) => entry.amount)
                  .reduce((partialSum, a) => partialSum + a, 0) / 100
              }
              displayType="input"
            />
          </div>
        </div>
      </div>
    ) : null;
  },
);

export default MonthlyBreakdown;
