import React from 'react';
import { HiExclamationCircle, HiX } from 'react-icons/hi';

export default function AlertMessage({ success, message, closeAlert }) {
  return (
    <div
      className={`${
        success
          ? 'bg-platform-cta-success-200 border-platform-cta-success-800'
          : 'bg-platform-cta-error-200 border-platform-cta-error-800'
      } border-t-4 rounded-b px-4 py-3 shadow-md`}>
      <div className="flex justify-between">
        <div className="flex py-1">
          <HiExclamationCircle
            className={`h-6 w-6 mr-4 text-2xl ${
              success
                ? 'text-platform-cta-success-800'
                : 'text-platform-cta-error-800'
            }`}
          />
          <div>
            <b>{success ? 'Success' : 'Failure'}</b> - {message}.
          </div>
        </div>
        <button type="button" aria-label="button" onClick={closeAlert}>
          <HiX className="h-6 w-6 mr-4 text-2xl" />
        </button>
      </div>
    </div>
  );
}
