import React from 'react';
import Chart from 'react-apexcharts';

function LineChart({ seriesData, categories, title }) {
  const chartProps = {
    series: seriesData,
    options: {
      chart: {
        height: 400,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: true,
        },
      },
      title: {
        text: title,
        align: 'left',
      },
      colors: ['#D6001C', '#F2A900', '#E87722'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories,
        title: {
          text: 'Month',
        },
      },
      yaxis: {
        title: {
          text: '£',
          rotate: -90,
        },
        min: 0,
        max: Math.max.apply(0, seriesData[2].data),
        labels: {
          formatter(value) {
            return value.toLocaleString();
          },
        },
        forceNiceScale: true,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
    },
  };

  return (
    <Chart
      options={chartProps.options}
      series={chartProps.series}
      type="line"
    />
  );
}

export default LineChart;
