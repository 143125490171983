import React from 'react';
import { HiX } from 'react-icons/hi';
import FormLayout from '../../components/dynamic-form/bsol_form_layout.json';

export default function Glossary({ glossaryOpen, setGlossaryOpen }) {
  const data = FormLayout.map((page) => page.rows.map((row) => row.fields))
    .flat(2)
    .sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0))
    .map((item) => `${item.label}: ${item.atamisLabel}`);

  return (
    <div
      className={`fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ${
        glossaryOpen
          ? 'transition-opacity opacity-100 duration-500 translate-x-0'
          : 'transition-all delay-500 opacity-0 translate-x-full'
      }`}>
      <div
        className={`right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform
              ${glossaryOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="relative flex flex-col overflow-y-scroll h-full">
          <div className="p-4 font-bold text-lg">Glossary</div>
          <div className="flex flex-col items-center">
            <div className="p-6">
              {data.map((item) => (
                <div key={item} className="text-xs">
                  {item}
                </div>
              ))}
            </div>
            <button
              className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
              type="button"
              onClick={() => setGlossaryOpen(false)}>
              <div className="flex items-center">
                <HiX className="text-xl mr-1" />
                Close
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
