import React, { useRef, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiDocumentDuplicate,
  HiDocumentText,
  HiDotsHorizontal,
  HiPencil,
  HiX,
} from 'react-icons/hi';
import axios from 'axios';
import EditProjectModal from '../existing-project/EditProjectModal';
import { useAPI } from '../../app/apiContext';
import DuplicateProjectModal from '../existing-project/DuplicateProjectModal';
import DeleteProjectModal from '../existing-project/DeleteProjectModal';
import ProjectHistoryModal from '../existing-project/ProjectHistoryModal';
import {
  buildBoolean,
  buildCurrency,
  buildDate,
  buildDateTime,
  buildFieldFromId,
  buildNotes,
  buildStatus,
  buildTitle,
} from '../../app/tableHelper';
import Loader from '../../components/loader/Loader';
import * as lookups from '../../app/lookups';
import FormLayout from '../../components/dynamic-form/bsol_form_layout.json';
import ButtonBar from './ButtonBar';
import { baseUrl, headers } from '../../app/lookups';

export default function ProjectsTable({ formLayout, projects }) {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] =
    useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showProjectHistoryModal, setShowProjectHistoryModal] = useState(false);

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);

  const ics = sessionStorage.getItem('ics');

  const customApiMultiFilter = (row, columnId, filterValue, api) => {
    if (filterValue.length === 0) {
      return row;
    }

    // splits based on separator and uses filter to remove all empty strings
    const filterValues = filterValue
      .split('&&')
      .filter((filter) => String(filter).trim())
      .map((value) => value.trim().toLowerCase());
    return filterValues.length === 0
      ? row
      : api
          .filter((t) =>
            filterValues.some((val) => t.name.toLowerCase().includes(val)),
          )
          .map((t) => t.id)
          .includes(row.getValue(columnId));
  };

  const customMultiFilter = (row, columnId, filterValue) => {
    if (filterValue.length === 0) {
      return row;
    }

    // splits based on separator and uses filter to remove all empty strings
    const filterValues = filterValue
      .split('&&')
      .filter((filter) => String(filter).trim())
      .map((value) => value.trim().toLowerCase());

    return filterValues.length === 0
      ? row
      : filterValues.some((val) =>
          String(row?.original[columnId])?.toLowerCase().includes(val),
        );
  };

  const getHeader = (columnId) =>
    formLayout
      .map((page) =>
        page.rows.map((row) =>
          row.fields.map((field) => ({ id: field.uid, label: field.label })),
        ),
      )
      .flat(2)
      .filter((h) => h.id === columnId)
      .map((h) => h.label)
      .toString();

  const ref = useRef();

  const {
    trusts,
    divisions,
    cipCategories,
    leads,
    mainAlternativeCategories,
    mainCpvCategories,
  } = useAPI();

  const handleEditProject = (project) => {
    axios
      .get(`${baseUrl}/workplan/project?ics=${ics}&project_id=${project.id}`, {
        headers,
      })
      .then((response) => {
        setSelectedProject(response.data);
        setShowEditProjectModal(true);
      });
  };

  const handleDuplicateProject = (project) => {
    axios
      .get(`${baseUrl}/workplan/project?ics=${ics}&project_id=${project.id}`, {
        headers,
      })
      .then((response) => {
        setSelectedProject(response.data);
        setShowDuplicateProjectModal(true);
      });
  };

  const handleDeleteProject = (project) => {
    setShowDeleteProjectModal(true);
    setSelectedProject(project);
  };

  const handleViewProjectHistory = (project) => {
    setShowProjectHistoryModal(true);
    setSelectedProject(project);
  };

  const buildActionsButtons = (project) => (
    <div className="flex py-1">
      <div className="pb-1">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                aria-label="view-project-button"
                id="view-project"
                type="button"
                onClick={() => handleEditProject(project)}
                className="mx-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiPencil />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Edit Project
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="pb-1">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                aria-label="duplicate-project-button"
                id="duplicate-project"
                type="button"
                onClick={() => handleDuplicateProject(project)}
                className="mx-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiDocumentDuplicate />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Copy Project
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="pb-1">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                aria-label="view-project-button-2"
                id="view-project"
                type="button"
                onClick={() => handleViewProjectHistory(project)}
                className="mx-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiDocumentText />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Project History
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="pb-1">
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                aria-label="delete-project-button-2"
                id="delete-project"
                type="button"
                onClick={() => handleDeleteProject(project)}
                className="mx-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400">
                <HiX />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                side="bottom"
                className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
                Delete Project
                <Tooltip.Arrow className="fill-platform-primary-grey-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </div>
  );

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('actions', {
      header: 'Actions',
      cell: (cell) => buildActionsButtons(cell.row.original),
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.title, {
      id: 'title',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildTitle(info),
      filterFn: 'titleFilter',
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildStatus(info),
      sortingFn: 'statusSort',
      filterFn: 'statusFilter',
    }),
    columnHelper.accessor((row) => row.trust, {
      id: 'trust',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildFieldFromId(info.getValue(), trusts),
      filterFn: 'trustFilter',
    }),
    columnHelper.accessor((row) => row.project_lead, {
      id: 'project_lead',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildFieldFromId(info.getValue(), leads),
      filterFn: 'leadFilter',
    }),
    columnHelper.accessor((row) => row.division, {
      id: 'division',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildFieldFromId(info.getValue(), divisions),
      filterFn: 'divisionFilter',
    }),
    columnHelper.accessor((row) => row.dept, {
      id: 'dept',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
      filterFn: 'deptFilter',
    }),
    columnHelper.accessor((row) => row.project_ref, {
      id: 'project_ref',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
      filterFn: 'projectRefFilter',
    }),
    columnHelper.accessor((row) => row.anticipated_delivery, {
      id: 'anticipated_delivery',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildDate(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.actual_delivery, {
      id: 'actual_delivery',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildDate(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.financial_year, {
      id: 'financial_year',
      header: 'Financial Year',
      cell: (info) => info.getValue(),
      filterFn: 'financialYearFilter',
    }),
    columnHelper.accessor((row) => row.contract_value, {
      id: 'contract_value',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildCurrency(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.cip_category, {
      id: 'cip_category',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildFieldFromId(info.getValue(), cipCategories),
      filterFn: 'cipCategoryFilter',
    }),
    columnHelper.accessor((row) => row.cip_target, {
      id: 'cip_target',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildCurrency(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.current_year_saving, {
      id: 'current_year_saving',
      header: 'In-Year Saving (£)',
      cell: (info) => buildCurrency(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.next_year_saving, {
      id: 'next_year_saving',
      header: 'Carry Over (£)',
      cell: (info) => buildCurrency(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('year_saving', {
      id: 'year_saving',
      header: '12-Month Saving (£)',
      cell: (cell) =>
        buildCurrency(
          cell.row.original.current_year_saving +
            cell.row.original.next_year_saving,
        ),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.route_to_market, {
      id: 'route_to_market',
      header: 'Route to Market',
      cell: (info) => info.getValue(),
      filterFn: 'routeToMarketFilter',
    }),
    columnHelper.accessor((row) => row.cm_classification, {
      id: 'cm_classification',
      header: 'Estimated Extension Value (£)',
      cell: (info) => info.getValue(),
      filterFn: 'cmClassificationFilter',
    }),
    columnHelper.accessor((row) => row.main_cpv_category, {
      id: 'main_cpv_category',
      header: 'Main CPV Category',
      cell: (info) => buildFieldFromId(info.getValue(), mainCpvCategories),
      filterFn: 'mainCPVCategoryFilter',
    }),
    columnHelper.accessor((row) => row.main_alternative_category, {
      id: 'main_alternative_category',
      header: 'Main Alternative Category',
      filterFn: 'mainAlternativeCategoryFilter',
      cell: (info) =>
        buildFieldFromId(info.getValue(), mainAlternativeCategories),
    }),

    columnHelper.accessor((row) => row.contract_duration, {
      id: 'contract_duration',
      header: 'Contract Duration',
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.estimated_extension_length, {
      id: 'estimated_extension_length',
      header: 'Estimated Extension Length (in months)',
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.estimated_extension_value, {
      id: 'estimated_extension_value',
      header: 'Estimated Extension Value (£)',
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.savings_type, {
      id: 'savings_type',
      header: 'Savings Type',
      cell: (info) => info.getValue(),
      filterFn: 'savingsTypeFilter',
    }),
    columnHelper.accessor((row) => row.savings_category, {
      id: 'savings_category',
      header: 'Savings Category',
      cell: (info) => info.getValue(),
      filterFn: 'savingsCategoryFilter',
    }),
    columnHelper.accessor((row) => row.public_procurement_regulations, {
      id: 'public_procurement_regulations',
      header: 'Public Procurement Regulations',
      cell: (info) => info.getValue(),
      filterFn: 'publicProcurementRegulationsFilter',
    }),
    columnHelper.accessor((row) => row.procurement_route, {
      id: 'procurement_route',
      header: 'Procurement Route',
      cell: (info) => info.getValue(),
      filterFn: 'procurementRouteFilter',
    }),
    columnHelper.accessor((row) => row.contract_type, {
      id: 'contract_type',
      header: 'Contract Type',
      cell: (info) => info.getValue(),
      filterFn: 'contractTypeFilter',
    }),
    columnHelper.accessor((row) => row.cip_ref, {
      id: 'cip_ref',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
      filterFn: 'cipRefFilter',
    }),
    columnHelper.accessor((row) => row.is_recurrent, {
      id: 'is_recurrent',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildBoolean(info.getValue()),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.approved_by, {
      id: 'approved_by',
      header: (info) => getHeader(info.column.id),
      cell: (info) => info.getValue(),
      filterFn: 'approvedByFilter',
    }),
    columnHelper.accessor((row) => row.used_pd, {
      id: 'used_pd',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildBoolean(info.getValue()),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: 'Project ID',
      cell: (info) => info.getValue(),
      filterFn: 'projectIdFilter',
    }),
    columnHelper.accessor((row) => row.date_created, {
      id: 'date_created',
      header: 'Creation Date',
      cell: (info) => buildDateTime(info.getValue()),
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.notes, {
      id: 'notes',
      header: (info) => getHeader(info.column.id),
      cell: (info) => buildNotes(info),
      enableSorting: false,
      filterFn: 'notesFilter',
    }),
  ];
  const clearFilters = () => {
    setColumnFilters();
  };
  const table = useReactTable({
    data: projects,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    sortingFns: {
      statusSort: (rowA, rowB, columnId) =>
        lookups.status
          .filter((s) => s.name === rowA.getValue(columnId).value)
          .map((s) => s.priority)
          .toString() <
        lookups.status
          .filter((s) => s.name === rowB.getValue(columnId).value)
          .map((s) => s.priority)
          .toString()
          ? 1
          : -1,
    },
    filterFns: {
      trustFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(row, columnId, filterValue, trusts),
      titleFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue, trusts),
      statusFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue, trusts),
      divisionFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(row, columnId, filterValue, divisions),
      deptFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      projectRefFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      financialYearFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      cipRefFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      projectIdFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      approvedByFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      notesFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      cipCategoryFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(row, columnId, filterValue, cipCategories),
      leadFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(row, columnId, filterValue, leads),
      routeToMarketFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      cmClassificationFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      mainAlternativeCategoryFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(
          row,
          columnId,
          filterValue,
          mainAlternativeCategories,
        ),
      mainCPVCategoryFilter: (row, columnId, filterValue) =>
        customApiMultiFilter(row, columnId, filterValue, mainCpvCategories),
      savingsTypeFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      savingsCategoryFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      procurementRouteFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      contractTypeFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
      publicProcurementRegulationsFilter: (row, columnId, filterValue) =>
        customMultiFilter(row, columnId, filterValue),
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  return (
    <>
      <Dialog.Root
        open={showEditProjectModal}
        onOpenChange={setShowEditProjectModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <EditProjectModal
            project={selectedProject}
            setShowModal={setShowEditProjectModal}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showDuplicateProjectModal}
        onOpenChange={setShowDuplicateProjectModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <DuplicateProjectModal
            project={selectedProject}
            setShowModal={setShowDuplicateProjectModal}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showDeleteProjectModal}
        onOpenChange={setShowDeleteProjectModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <DeleteProjectModal
            project={selectedProject}
            setShowModal={setShowDeleteProjectModal}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={showProjectHistoryModal}
        onOpenChange={setShowProjectHistoryModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-platform-primary-grey-800 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
          <ProjectHistoryModal
            project={selectedProject}
            formLayout={formLayout}
            setShowModal={setShowProjectHistoryModal}
            ref={ref}
          />
        </Dialog.Portal>
      </Dialog.Root>
      <ButtonBar
        projectsToExport={table
          .getFilteredRowModel()
          .rows.map((r) => r.original)}
        formLayout={FormLayout}
        clearFilters={clearFilters}
        allProjects={projects}
      />
      <div
        className=" overflow-y-auto overflow-x-auto w-maxFull"
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          overflowX: 'auto',
        }}>
        {projects === null || projects.length === 0 ? (
          <Loader fullscreen />
        ) : (
          <table className="text-sm text-left text-platform-primary-grey-800">
            <thead className="sticky top-0 text-xs text-platform-primary-grey-800 uppercase bg-platform-secondary-grey-200">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="py-1 px-3 border border-platform-primary-grey-800">
                      {header.isPlaceholder ? null : (
                        <div className="flex flex-col items-center">
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <div
                            className={`flex items-center ${
                              header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : ''
                            }`}
                            onClick={header.column.getToggleSortingHandler()}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getIsSorted().toString() ===
                            'asc' ? (
                              <HiChevronUp className="ml-1 text-xl" />
                            ) : header.column.getIsSorted() === 'desc' ? (
                              <HiChevronDown className="ml-1 text-xl" />
                            ) : header.column.getCanSort() ? (
                              <HiDotsHorizontal className="ml-1 text-xl" />
                            ) : null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <input
                                type="text"
                                value={header.column.getFilterValue() ?? ''}
                                onChange={(e) => {
                                  header.column.setFilterValue(e.target.value);
                                }}
                                placeholder="Search..."
                                className={`appearance-none block ${
                                  header.column.id === 'title' ||
                                  header.column.id === 'notes'
                                    ? 'w-maxfull'
                                    : 'w-[7rem]'
                                } m-1 py-2 px-4 leading-tight text-start rounded-md bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  className="bg-white hover:bg-platform-ainc-grey-400"
                  key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="px-2 border">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex justify-end items-center mx-1 mt-1">
        <button
          type="button"
          aria-label="index-button"
          className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          <HiChevronDoubleLeft className="text-2xl" />
        </button>
        <button
          type="button"
          aria-label="previous-pages-button"
          className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          <HiChevronLeft className="text-2xl" />
        </button>
        <span className="flex items-center mx-1">
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <button
          type="button"
          aria-label="next-page-button"
          className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          <HiChevronRight className="text-2xl" />
        </button>
        <button
          type="button"
          aria-label="page-index-button"
          className="hover:bg-platform-primary-orange-800 hover:text-white text-sm font-bold mx-1 px-4 py-1 rounded-full bg-white text-platform-primary-orange-800 border-platform-primary-orange-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          <HiChevronDoubleRight className="text-2xl" />
        </button>
        <span className="flex items-center mx-1">
          Go to page:
          <input
            type="number"
            min="1"
            value={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            disabled={table.getPageCount() < 2}
            className="appearance-none block w-[5rem] mx-1 py-2 px-4 leading-tight text-start rounded-md border border-platform-primary-orange-800 bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
          />
        </span>
        <select
          className="rounded-md border border-platform-primary-orange-800 bg-white text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200"
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}>
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
