import React, { forwardRef, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import axios from 'axios';
import { baseUrl, headers } from '../../app/lookups';
import ProjectHistoryTable from './ProjectHistoryTable';

const ProjectHistoryModal = forwardRef(
  ({ setShowModal, project, formLayout }, ref) => {
    const [projectHistory, setProjectHistory] = useState([]);

    useEffect(() => {
      axios
        .get(`${baseUrl}/workplan/project-history?project_id=${project.id}`, {
          headers,
        })
        .then((response) => {
          setProjectHistory(response.data);
        });
    }, [project]);

    return (
      <div ref={ref}>
        <Dialog.Content className="w-[90vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="relative my-6 mx-auto">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
                <div className="text-3xl font-bold text-platform-primary-grey-800">
                  Project History
                </div>
              </div>
              <div className="relative p-6 flex-auto max-h-[22rem] overflow-y-scroll">
                <ProjectHistoryTable
                  projectHistory={projectHistory}
                  formLayout={formLayout}
                />
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-platform-primary-grey-200 rounded-b">
                <button
                  className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                  type="button"
                  onClick={() => setShowModal(false)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </div>
    );
  },
);

export default ProjectHistoryModal;
